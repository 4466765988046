
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'

export default defineComponent({
  name: 'OrganizationContactsFilter',
  components: {
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const topFilterRef = ref()
    const search = ref('')
    const { openModalNewContact } = useContactsOpenModal()

    return {
      topFilterRef,
      search,
      openModalNewContact,
    }
  },
})
