
import { defineComponent, ref, computed } from 'vue'
import { useModes } from '@/compositions/modes'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ContactsAllContactsTable from '@/components/pages/contacts/allContacts/ContactsAllContactsTable.vue'
import OrganizationContactsFilter from '@/components/pages/contacts/organizationDetails/OrganizationContactsFilter.vue'
import ContactsAllContactsEmpty from '@/components/pages/contacts/allContacts/ContactsAllContactsEmpty.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import { useRoute } from 'vue-router'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'OrganizationDetailsContacts',
  components: {
    TmButton,
    TmDropdown,
    TmDropdownItem,
    WhiteBlock,
    ContactsAllContactsTable,
    OrganizationContactsFilter,
    ContactsAllContactsEmpty,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openModal } = useModals()

    const search = ref('')
    const selected = ref<ContactLine[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone-slot', width: '17%' },
      { text: 'Email', value: 'email-slot', width: '18%' },
      { text: 'Type', value: 'type-slot', width: '14%' },
    ])
    const tableItems = ref<ContactLine[]>(getTableData('contactsAllContacts'))
    const filteredTableItems = computed<ContactLine[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })
    const route = useRoute()
    const activePage = computed(() => route.meta.title)

    return {
      openModal,
      search,
      selected,
      tableHeaders,
      tableItems,
      isEmptyMode,
      filteredTableItems,
      activePage,
    }
  },
})
