import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    ref: "topFilterRef",
    "hide-columns-additional-buttons": "",
    selected: _ctx.selected,
    "visible-buttons": 5
  }, {
    "filter-line-left-prepend-permanent": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Add contacts",
        icon: "add",
        onClick: _ctx.openModalNewContact
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["selected"]))
}